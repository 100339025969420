export class Bill {

  client_name?: string;
  client_email?: string;
  client_account?: string;
  total_amount_due?: string;
  billing_month?: string;
  model?: string;
  bank_account?: string;
  amount?: number;

  public constructor(data?: any) {
    this.client_name = data.klijent;
    this.client_email = data.email;
    this.client_account = data.poziv_na_broj;
    this.total_amount_due = data.dug;
    this.billing_month = data.racun ? data.racun.slice(0, 2) + '/' + data.racun.slice(2) : null;
    this.model = data.model;
    this.bank_account = data.racun_id;

    if (!data.dug) {
      this.amount = null;
    } else {
      const amount = parseFloat(data.dug) * 100;
      this.amount = amount <= 0 ? 0 : amount;
    }
  }
}
